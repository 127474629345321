// https://github.com/codeAdrian/clay.css/

/* --- DEFAULT VALUES --- */
$default-background: rgba(0, 0, 0, 0.005);
$default-border-radius: 32px;
$default-shadow-outset: 8px 8px 16px 0 rgba(0, 0, 0, 0.25);
$default-shadow-inset-primary: -8px -8px 16px 0 rgba(0, 0, 0, 0.25);
$default-shadow-inset-secondary: 8px 8px 16px 0 rgba(255, 255, 255, 0.2);

/* --- MIXIN --- */
@mixin clay(
    $background: $default-background,
    $border-radius: $default-border-radius,
    $shadow-outset: $default-shadow-outset,
    $shadow-inset-primary: $default-shadow-inset-primary,
    $shadow-inset-secondary: $default-shadow-inset-secondary
) {
    background: $background;
    border-radius: var(--clay-border-radius, 32px);
    box-shadow: $shadow-outset,
        inset $shadow-inset-primary,
        inset $shadow-inset-secondary;
}

/* --- UTIL CLASS --- */
.clay {
    @include clay(
        $background: var(--clay-background, $default-background),
        $border-radius: var(--clay-border-radius, $default-border-radius),
        $shadow-outset: var(--clay-shadow-outset, $default-shadow-outset),
        $shadow-inset-primary: var(--clay-shadow-inset-primary, $default-shadow-inset-primary),
        $shadow-inset-secondary: var(--clay-shadow-inset-secondary, $default-shadow-inset-secondary)
    );
}


.clay {
    &.clay-card {
        /* Modify clay.css properties */
        --clay-background: #f76d6d;
        --clay-border-radius: 48px;
      
        /* Extended styles */
        color: #f1f1f1;
        padding: 48px;
        font-family: 'Open Sans', sans-serif;
    }
}
