.site-navbar {
	top: 0;
	transition: .3s all ease;
    // background: linear-gradient($primary, transparent);
    // background-color: $gray-800;
    // background: linear-gradient(rgba($gray-800, 1), transparent);
    // overflow: hidden; // ẩn ảnh bg-effect, bị lỗi dropdown
    // background-color: $white;
    background-color: $primary;

    .header-text {
        color: $white;
    }

    @media (min-width: 992px) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background-color: transparent;

        .header-text {
            color: #212529; // .text-body
        }
    }

    &.shrink {
		padding-top: 0rem;
		padding-bottom: 0rem;
        background-color: $primary;

        .header-text {
            color: $white;
        }
	}

    .bg-effect {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 60px;

        overflow: hidden;

        z-index: -1; // để click được vào các link

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 200px;
            background: url(/images/bg-effect.png) top center;
            background-size: contain;
            // background-repeat: no-repeat;
            opacity: 0.075;
        }
    }

	.site-logo {
		width: 2rem;
		height: 2rem
	}
}


.landing-page {
    .site-navbar {
        .header-text {
            color: $white;
        }
    }    
}
